body {
  margin: 0;
  font-family: 'Arvo';
  src: url('/public/fonts/Arvo/Arvo-Regular') format('truetype'),
        url('/public/fonts/Arvo/Arvo-Bold') format('truetype'),
        url('/public/fonts/Arvo/Arvo-BoldItalic') format('truetype'),
        url('/public/fonts/Arvo/Arvo-Italic') format('truetype');
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
  background-color: rgb(48, 152, 243);
  color: white;
  height:100%;
}

.signup-fields{
  display:flex;
  grid-gap: 10px;
  gap: 10px;
}

.MuiFormControl-root.MuiTextField-root{
  width: 300px;
}


.nav-bar {
  padding-top: 20px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 10px;
  display: flex;
  justify-content: space-between;
}

.nav-bar > h1 {
  font-size: 32px;
  margin: 0;
  padding: 0;
}

.image-placeholder{
  width: 50%;
}

.slogan {
  margin:0;
  padding:0;
  font-size: 42px;
}

.body {
  text-align: center;
  position: absolute;
  top: 20%;
  width: 100%;
}

.copy{
  width: 100%;
  padding-top: 10px;
  display: flex;
}

.value-propositions{
  text-align: left;
}

.signup-items{
  list-style: none;
}

.value-statements > li {
  font-size: 24px;
  font-weight: bold;
}

.focus {
  color: #fffd74;
}

.image-placeholder{
  width: 40%;
}

.value-propositions{
  width: 60%;
}

.signup > div,button {
  padding-left: 10px;
  padding-right: 10px;
}

.navigatable{
  padding-left: 20px;
  text-decoration: underline;
  display: flex;
}

.navigatable:hover{
  cursor: pointer;
}


#signup-trigger {
  max-height: 50px;
  min-width: 100px;
}

.titles {
  display: flex;
}

.navigatable span {
  align-self: center;
}

#getintouch-header {
  max-height: 50px;
}

#call-to-action{
  text-decoration: underline;
}

@media only screen and (max-width : 768px) { 
  .image-placeholder{
    width: 0% !important;
  }

  .titles > h1, h2, h3{
    padding-bottom: 0;
    margin-bottom: 0;
  }

  .navigatable{
    text-decoration: underline;
    padding-left: 20px;
    cursor:pointer;
    display: flex;
  }



  .value-propositions{
    width:100% !important;
  }

  form.signup{
    display:flex;
    justify-content: space-between;
  }
  #getintouch-header{
    display: none;
  }



  #signup-trigger{
    position: relative;
  }
  .value-statements > li {
    font-size: 18px;
    font-weight: bold;
  }
  .slogan{
    font-size: 28px;
  }

  #call-to-action{
    font-size: 26px;
  }

  #email{
    font-size: 12px;
  }

  #signup-trigger {
    margin-right:10px;

  }
  .signup-items{
    padding-left: 20px;
  }

  .footer {
    display: block !important;
  }

  .about {
    width: 90% !important;
  }

}
.about{
  width:50%;
  margin:0 auto;
  text-align: left;
  font-size: 18px;
}

#shiritori{
  color:white;
}

#shiritori:hover{
  color: #fffd74;
}

.navigatable:hover {
  color: #fffd74;
}

.footer{
  position:absolute;
  right: 5%;
  bottom: 5%;
  display: none;
}
