body {
  margin: 0;
  font-family: 'Arvo';
  src: url('/public/fonts/Arvo/Arvo-Regular') format('truetype'),
        url('/public/fonts/Arvo/Arvo-Bold') format('truetype'),
        url('/public/fonts/Arvo/Arvo-BoldItalic') format('truetype'),
        url('/public/fonts/Arvo/Arvo-Italic') format('truetype');
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
